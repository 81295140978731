<template>

  <v-container id="ReplenishmentOfBalancePage" class="h-100">

    <div class="back-button text-right">
      <v-btn icon @click="goBack">
        <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div class="pb-3 mb-3">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Что</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
    </div>

    <div class="remaining-space">
      <v-form ref="form_new_order" v-model="validate_balance" lazy-validation>

        <b-field
          label="Введите сумму"
          expanded>
          <v-text-field
            v-model="amount"
            type="number"
            inputmode="numeric"
            :rules="amountRules"
            outlined
            required
          ></v-text-field>
        </b-field>
      </v-form>

      <div
        ref="container"
        class="chips-container"
        @mousedown="onMouseDown"
        @mousemove="onMouseMove"
        @mouseup="onMouseUp"
        @mouseleave="onMouseLeave"
      >
        <v-chip
          v-for="(amount, index) in adjustedAmounts"
          :key="index"
          color="primary"
          variant="tonal"
          class="chip"
          @click="selectAmount(amount)"
        >
          {{ amount }}
        </v-chip>
      </div>

    </div>

    <div class="pa-3 pb-0" style="text-align: center;">
      <p class="buttons mb-0 flex justify-center align-center">
        <b-button
          @click="goToPaymentPage"
          class="w-100"
          type="is-success">
          Пополнить
        </b-button>
      </p>
    </div>

    <FullScreenLoader :show="isLoading" />

  </v-container>

</template>

<script>
export default {
  name: 'CancellationOfOrder',
  data() {
    return {
      isLoading: false,
      validate_balance: false,
      amount: 0,
      amounts: [500, 1000, 3000, 5000, 10000],
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
    };
  },
  created() {
    this.amount = 0;
    this.$store.dispatch('AUTH');
  },
  computed: {
    balance() {
      return this.$store.getters.BALANCE;
    },
    amountRules() {
      return this.$store.getters.AMOUNT_RULES;
    },
    adjustedAmounts() {
      if (this.balance < 0) {
        return [Math.abs(this.balance), ...this.amounts];
      }
      return this.amounts;
    }
  },
  methods: {
    selectAmount(amount) {
      this.amount = amount;
    },
    goBack() {
      this.$router.back();
    },
    goToPaymentPage() {
      if (this.$refs.form_new_order.validate()) {
        this.isLoading = true;
        this.$store.dispatch('NEW_PAYMENT', this.amount).then((data) => {
          window.location.href = data;
        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Ошибка! Попробуйте позже',
            type: 'is-danger'
          });
        }).finally(() => {
          this.isLoading = false;
        });
      } else {
        this.$buefy.toast.open({
          message: 'Введите валидную сумму',
          type: 'is-danger',
        });
      }
    },
    onMouseDown(e) {
      this.isDragging = true;
      this.startX = e.pageX - this.$refs.container.offsetLeft;
      this.scrollLeft = this.$refs.container.scrollLeft;
    },
    onMouseMove(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.container.offsetLeft;
      const walk = (x - this.startX) * 2; // Скорость прокрутки
      this.$refs.container.scrollLeft = this.scrollLeft - walk;
    },
    onMouseUp() {
      this.isDragging = false;
    },
    onMouseLeave() {
      this.isDragging = false;
    },
  },
};
</script>

<style scoped>
.block-content img {
  max-width: 100%;
  height: auto;
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

#ReplenishmentOfBalancePage.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}

.title.is-4 {
  margin-bottom: 0px !important;
  line-height: normal !important;
}


.chips-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
}

.chips-container::-webkit-scrollbar {
  display: none; /* Для Chrome, Safari и Opera */
}

.chip {
  margin-right: 8px;
}

.chips-container::-webkit-scrollbar {
  height: 8px;
}

.chips-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.chips-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.v-chip.primary {
  background-color: #e3ecf7 !important;
  color: #1866c0 !important;
}

</style>
